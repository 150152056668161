import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['userConsent']);
  const [visible, setVisible] = useState(!cookies.userConsent);

  useEffect(() => {
    if (cookies.userConsent === 'accepted') {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [cookies.userConsent]);

  const handleAccept = () => {
    setCookie('userConsent', 'accepted', { path: '/', maxAge: 365 * 24 * 60 * 60 }); // Set cookie for 1 year
    setVisible(false);
  };

  const handleReject = () => {
    // Do not set any cookie, just hide the banner
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-black via-[#342ac8] to-black text-white py-8 z-50 bg-opacity-90">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm md:text-base mb-4 md:mb-0">
          We use cookies to ensure you get the best experience on our website.{' '}
          <Link to="/privacy-policy" className="underline">
            Learn more
          </Link>
        </p>
        <div className="flex space-x-4">
          <button
            onClick={handleAccept}
            className="bg-[#56d879] text-white px-4 py-2 rounded-md"
          >
            Accept
          </button>
          <button
            onClick={handleReject}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
