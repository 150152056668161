import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import banner1 from '../../../Assests/hero/hero1.jpg';
import banner2 from '../../../Assests/hero/hero2.jpg';
import banner3 from '../../../Assests/hero/hero3.jpg';
import banner4 from '../../../Assests/hero/hero4.jpg';
import banner5 from '../../../Assests/hero/hero5.jpg';
import banner6 from '../../../Assests/hero/hero6.png'; // Import the new image

const Hero = () => {
  const slides = [
    {
      image: banner1,
      heading: "Deliveries that make you smile",
      subheading: "Tanzania's Finest products from our farmers to your family."
    },
    {
      image: banner2,
      heading: "Fresh and Organic",
      subheading: "Bringing nature's bounty straight to your door."
    },
    {
      image: banner3,
      heading: "Quality You Can Trust",
      subheading: "We deliver the best products for your family."
    },
    {
      image: banner4,
      heading: "Sustainable Farming",
      subheading: "Supporting local farmers and sustainable practices."
    },
    {
      image: banner5,
      heading: "Healthy Living",
      subheading: "Fresh, healthy, and delicious products every day."
    },
    {
      image: banner6, // Use the new image
      heading: "Pay with Tigo Tanzania and Visa",
      subheading: "Convenient and secure payment options."
    },
  ];

  return (
    <div className="relative w-full bg-no-repeat flex items-center justify-center py-8 lg:py-12 xl:py-16">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        className="w-[95%] sm:w-[90%] lg:w-[80%] xl:w-[70%] rounded-lg"
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${slide.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
            }}
            className="relative flex items-center justify-center w-full bg-no-repeat rounded-lg min-h-[20vh] md:min-h-[25vh] lg:min-h-[30vh] xl:min-h-[45vh]"
          >
            <div className="text-center bg-white bg-opacity-70 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%]">
              <h1 className="text-[18px] leading-[1.2] sm:text-[20px] lg:text-[24px] xl:text-[28px] text-gray-800 font-semibold capitalize">
                {slide.heading} <br /> {slide.subheading}
              </h1>
              <p className="pt-3 text-[12px] sm:text-[14px] lg:text-[16px] xl:text-[18px] font-poppins font-normal text-black opacity-75">
                Thousands of healthy, fresh and delicious products delivered to your kitchen everyday <br /> Delivered to your door.
              </p>
              <Link to="/products" className="inline-block">
                <div className="mt-4 bg-red-500 hover:bg-red-600 text-white font-poppins text-[14px] sm:text-[16px] lg:text-[18px] xl:text-[20px] py-2 px-4 lg:py-3 lg:px-6 xl:py-4 xl:px-8 rounded-lg">
                  Shop Now
                </div>
              </Link>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Hero;
