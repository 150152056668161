import Dairy from "../Assests/images/milk.jpeg"
import plantBasedImage from '../Assests/images/plant_based.jpeg';
import pantryImage from '../Assests/images/pantry.jpg';
import fruitVegImage from '../Assests/images/fruit_veg.jpeg';
import readyToEatImage from '../Assests/images/ready_to_eat.jpeg';
import drinksImage from '../Assests/images/drinks.jpeg';
import organicImage from '../Assests/images/organic.jpeg';
import seaFoodImage from '../Assests/images/sea_food.jpg';
import CerealsImage from "../Assests/images/cereals.jpeg"
import othersImage from '../Assests/images/others.jpg';

// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Best Selling",
    url: "/best-selling",
  },
  {
    title: "Products",
    url: "/products",
  },
  // {
  //   title: "Events",
  //   url: "/events",
  // },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "From all orders over TZS 10,000",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="https://greenspoon.co.ke/wp-content/uploads/2024/06/Screenshot-2024-07-13-102804.png"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
];

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Meat",
    subTitle: "",
    image_Url:
      "https://greenspoon.co.ke/wp-content/uploads/2024/06/Screenshot-2024-07-13-102804.png",
  },
  {
    id: 2,
    title: "Diary",
    subTitle: "",
    image_Url:Dairy
  },
  {
    id: 3,
    title: "Plant Based",
    subTitle: "",
    image_Url: plantBasedImage,
  },
  {
    id: 4,
    title: "Pantry",
    subTitle: "",
    image_Url: pantryImage,
  },
  {
    id: 5,
    title: "Fruit & Veg",
    subTitle: "",
    image_Url: fruitVegImage,
  },
  {
    id: 6,
    title: "Ready To Eat",
    subTitle: "",
    image_Url: readyToEatImage,
  },
  {
    id: 7,
    title: "Drinks",
    subTitle: "",
    image_Url: drinksImage,
  },
  {
    id: 8,
    title: "Organic",
    subTitle: "",
    image_Url: organicImage,
  },
  {
    id: 9,
    title: "Sea Food",
    subTitle: "",
    image_Url: seaFoodImage,
  },
   {
    id: 10,
    title: "Cereals",
    subTitle: "",
    image_Url: CerealsImage,
  },
  {
    id: 10,
    title: "Others",
    subTitle: "",
    image_Url: othersImage,
  },
];

// product Data
export const productsData = [
  {
    id: 1,
    title: "Organic Apples",
    description: "Fresh and crispy organic apples.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
    price: 10.99,
    category: "Fruit & Veg",
  },
  {
    id: 2,
    title: "Free Range Chicken",
    description: "Tender free range chicken, perfect for grilling.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
    price: 15.49,
    category: "Meat",
  },
  {
    id: 3,
    title: "Almond Milk",
    description: "Nutritious almond milk, ideal for dairy-free diets.",
    image_Url:
      "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png",
    price: 4.99,
    category: "Dairy",
  },
  {
    id: 4,
    title: "Whole Grain Bread",
    description: "Healthy whole grain bread with seeds.",
    image_Url:
      "https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png",
    price: 3.49,
    category: "Pantry",
  },
  {
    id: 5,
    title: "Frozen Strawberries",
    description: "Delicious frozen strawberries, great for smoothies.",
    image_Url:
      "https://images.squarespace-cdn.com/content/v1/55e048cde4b0dcaf5450d1c4/1469817407888-4U1SKDJ0Q0PRAKRT4WHW/ke17ZwdGBToddI8pDm48kMv4R5m7qREp4zJ6oa4nStp7gQa3H78H4a6WE5ZBCzqF9g7cL1LP7un4uPpLsEkSmdZz0-ktg4OYwJFP9Y0Yeic6F6ZEVLBvY23KNf6xzHwFnf0O7I2B0GnGmCCKICw.png?format=500w",
    price: 5.99,
    category: "Frozen Food",
  },
  {
    id: 6,
    title: "Organic Carrots",
    description: "Sweet and crunchy organic carrots.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/OrganicCarrots.jpg?v=1659592838",
    price: 6.49,
    category: "Fruit & Veg",
  },
  {
    id: 7,
    title: "Grass-Fed Beef",
    description: "High-quality grass-fed beef steaks.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/GrassFedBeef.jpg?v=1659592838",
    price: 20.99,
    category: "Meat",
  },
  {
    id: 8,
    title: "Greek Yogurt",
    description: "Creamy and rich Greek yogurt, perfect for breakfast.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/GreekYogurt.jpg?v=1659592838",
    price: 3.99,
    category: "Dairy",
  },
  {
    id: 9,
    title: "Pasta",
    description: "Classic pasta, ideal for a quick meal.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/Pasta.jpg?v=1659592838",
    price: 2.99,
    category: "Pantry",
  },
  {
    id: 10,
    title: "Frozen Peas",
    description: "Sweet and tender frozen peas.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/FrozenPeas.jpg?v=1659592838",
    price: 4.49,
    category: "Frozen Food",
  },
  {
    id: 11,
    title: "Avocado",
    description: "Creamy and ripe avocados.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/Avocado.jpg?v=1659592838",
    price: 2.99,
    category: "Fruit & Veg",
  },
  {
    id: 12,
    title: "Chicken Sausages",
    description: "Juicy chicken sausages, perfect for grilling.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/ChickenSausages.jpg?v=1659592838",
    price: 12.99,
    category: "Meat",
  },
  {
    id: 13,
    title: "Coconut Milk",
    description: "Rich and creamy coconut milk.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/CoconutMilk.jpg?v=1659592838",
    price: 5.49,
    category: "Dairy",
  },
  {
    id: 14,
    title: "Granola",
    description: "Crunchy granola, great for breakfast or snacking.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/Granola.jpg?v=1659592838",
    price: 4.79,
    category: "Pantry",
  },
  {
    id: 15,
    title: "Mixed Berries",
    description: "Frozen mixed berries, perfect for smoothies and desserts.",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/MixedBerries.jpg?v=1659592838",
    price: 6.99,
    category: "Frozen Food",
  },
];

export const footerProductLinks = [
  {
    name: "About us",
    link: "/about-us",
  },
  {
    name: "Careers",
    link: "/coming-soon",
  },
  {
    name: "Store Locations",
    link: "/coming-soon",
  },
  {
    name: "Our Blog",
    link: "/coming-soon",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy"
  },
];

export const footercompanyLinks = [
  {
    name: "Meat",
  },
  {
    name: "Dairy",
  },
  {
    name: "Fruits and Veg",
  },
  {
    name: "Drinks",
  },
  {
    name: "Organic",
  },
];

export const footerSupportLinks = [
  {
    name: "FAQ",
    link: "/faq",
  },
  {
    name: "Contact Us",
  },
  {
    name: "Return Policy",
    link: "/return-policy",
  },
  {
    name: "Terms And Conditions",
    link: "/terms-and-conditions",
  },
  {
    name: "Site Map",
    link: "https://www.danggroup.ltd/sitemap.txt",
  },
];
