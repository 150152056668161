import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header activeHeading={7} />
      <PrivacyPolicyContent />
      <Footer />
    </div>
  );
};

const PrivacyPolicyContent = () => {
  return (
    <div className={`${styles.section} my-8 p-6 bg-white rounded-lg shadow-lg`}>
      <h2 className="text-4xl font-extrabold text-blue-800 mb-6 text-center">Privacy Policy</h2>
      <div className="text-lg text-gray-700 space-y-6">
        <p>
          Our Privacy Policy was created on July 23, 2023.
        </p>
        <p>
          This Privacy Policy describes Our policies and procedures on the collection, use, and
          disclosure of Your information when You use the Service and tells You about Your
          privacy rights and how the law protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using the Service,
          You agree to the collection and use of information in accordance with this Privacy
          Policy.
        </p>

        <h3 className="text-3xl font-bold text-blue-800 mt-8">Interpretation and Definitions</h3>
        <h4 className="text-2xl font-semibold text-blue-600 mt-4">Interpretation</h4>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless
          of whether they appear in singular or in plural.
        </p>
        <h4 className="text-2xl font-semibold text-blue-600 mt-4">Definitions</h4>
        <p>
          For the purposes of this Privacy Policy:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li><strong>"Account"</strong> means a unique account created for You to access our Service or parts of our Service.</li>
          <li><strong>"Company"</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to DAN’G GROUP OF COMPANIES LIMITED.</li>
          <li><strong>"Country"</strong> refers to TANZANIA.</li>
          <li><strong>"Cookies"</strong> are small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li><strong>"Device"</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
          <li><strong>"Personal Data"</strong> is any information that relates to an identified or identifiable individual.</li>
          <li><strong>"Service"</strong> refers to the Website.</li>
          <li><strong>"Service Provider"</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.</li>
          <li><strong>"Usage Data"</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
          <li><strong>"Website"</strong> refers to www.danggroup.ltd, accessible from https://www.danggroup.ltd/</li>
          <li><strong>"You"</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h3 className="text-3xl font-bold text-blue-800 mt-8">Collecting and Using Your Personal Data</h3>
        <h4 className="text-2xl font-semibold text-blue-600 mt-4">Types of Data Collected</h4>
        <h5 className="text-xl font-semibold text-blue-500 mt-4">Personal Data</h5>
        <p>
          While using Our Service, We may ask You to provide Us with certain personally
          identifiable information that can be used to contact or identify You. Personally
          identifiable information may include, but is not limited to:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Shop name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal Code, City</li>
        </ul>

        <h5 className="text-xl font-semibold text-blue-500 mt-4">Usage Data</h5>
        <p>
          Usage Data is collected automatically when using the Service.
        </p>
        <p>
          Usage Data may include information such as Your Device's Internet Protocol address
          (e.g. IP address), browser type, browser version, the pages of our Service that You visit,
          the time and date of Your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may collect certain
          information automatically, including, but not limited to, the type of mobile device You
          use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
          operating system, the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You visit our
          Service or when You access the Service by or through a mobile device.
        </p>

        <h4 className="text-2xl font-semibold text-blue-600 mt-4">Tracking Technologies and Cookies</h4>
        <p>
          We use Cookies and similar tracking technologies to track the activity on Our Service
          and store certain information. Tracking technologies used are beacons, tags, and
          scripts to collect and track information and to improve and analyze Our Service. The
          technologies We use may include:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device.
            You can instruct Your browser to refuse all Cookies or to indicate when a Cookie
            is being sent. However, if You do not accept Cookies, You may not be able to
            use some parts of our Service. Unless you have adjusted Your browser setting
            so that it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small
            electronic files known as web beacons (also referred to as clear gifs, pixel tags,
            and single-pixel gifs) that permit the Company, for example, to count users who
            have visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain section and verifying
            system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your
          personal computer or mobile device when You go offline, while Session Cookies are
          deleted as soon as You close Your web browser.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out below:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Necessary / Essential Cookies</strong><br />
            <strong>Type:</strong> Session Cookies<br />
            <strong>Administered by:</strong> Tz<br />
            <strong>Purpose:</strong> These Cookies are essential to provide You with services available
            through the Website and to enable You to use some of its features. They help to
            authenticate users and prevent fraudulent use of user accounts. Without these
            Cookies, the services that You have asked for cannot be provided, and We only
            use these Cookies to provide You with those services.
          </li>
          <li>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong><br />
            <strong>Type:</strong> Persistent Cookies<br />
            <strong>Administered by:</strong> Tz<br />
            <strong>Purpose:</strong> These Cookies identify if users have accepted the use of cookies on
            the Website.
          </li>
          <li>
            <strong>Functionality Cookies</strong><br />
            <strong>Type:</strong> Persistent Cookies<br />
            <strong>Administered by:</strong> Tz<br />
            <strong>Purpose:</strong> These Cookies allow us to remember choices You make when You
            use the Website, such as remembering your login details or language preference.
            The purpose of these Cookies is to provide You with a more personal experience
            and to avoid You having to re-enter your preferences every time You use the
            Website.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
