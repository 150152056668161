import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import ReactPaginate from "react-paginate";

const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [currentPage, setCurrentPage] = useState(0);
  const productsPerPage = 25;

  // Check if allProducts is defined and is an array
  if (!allProducts || !Array.isArray(allProducts)) {
    return <div>No products available</div>;
  }

  // Logic for displaying products
  const offset = currentPage * productsPerPage;
  const currentProducts = allProducts.slice(offset, offset + productsPerPage);
  const pageCount = Math.ceil(allProducts.length / productsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="py-6 bg-gray-100">
      <div className={`${styles.section}`}>
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Featured Products</h1>
        </div>

        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {currentProducts && currentProducts.length !== 0 && (
            <>
              {currentProducts.map((product, index) => (
                <ProductCard data={product} key={index} />
              ))}
            </>
          )}
        </div>

        {/* Pagination Component */}
        {allProducts.length > productsPerPage && (
          <div className="mt-8 flex justify-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"flex items-center space-x-2"}
              pageClassName={"px-3 py-2 border border-gray-300 rounded-lg cursor-pointer"}
              previousClassName={"px-3 py-2 bg-gray-200 rounded-lg"}
              nextClassName={"px-3 py-2 bg-gray-200 rounded-lg"}
              breakClassName={"px-3 py-2 bg-gray-100 border border-gray-300 rounded-lg"}
              activeClassName={"bg-blue-500 text-white"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedProduct;
