import React from "react";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const TermsAndConditions = () => {
  return (
    <>
      <Header activeHeading={5} />
      <div className={`${styles.section} mt-10`}>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-center mb-8">Terms and Conditions</h1>
          <div className="space-y-6">
            <Section title="1. Parties to this agreement">
              <p>
                This agreement (referred to hereinafter as the Agreement) contains the terms and
                conditions for the use of this website (the "Site"), including without limitation for ordering
                products. By using the Site, you confirm that you accept and will comply with these
                terms and conditions.
              </p>
              <p>
                If you place an order through this Site, the seller who is registered will handle the
                fulfilment of such purchase.
              </p>
              <p>
                Our Address is as follows
                <br />
                Ntyuka Kijiweni
                <br />
                P.o Box 372
                <br />
                Dodoma
                <br />
                Tel: +255 625 377 978
                <br />
                Email: danggroupplc@gmail.com
                <br />
                The Website/App is owned and operated by DAN’G GROUP OF COMPANIES LIMITED.
              </p>
            </Section>

            <Section title="2. Use of the Site">
              <ul className="list-disc list-inside space-y-2">
                <li>The Site is intended for use by our vendors/sellers and customers.</li>
                <li>You must be Fifteen or older to order products using the Site.</li>
                <li>
                  This Site, including without limitation images, drawings, sound, graphics, and text, is
                  protected by worldwide copyright and other proprietary laws and treaty provisions.
                  DAN’G GROUP OF COMPANIES LIMITED grants you a limited license to access and
                  make personal use of this Site, and not to download, except where specifically invited,
                  nor to duplicate, sell or modify, any portion of this Site without express written
                  authorization from DAN’G GROUP OF COMPANIES LIMITED.
                </li>
                <li>
                  The images of the products on the website are for illustrative purposes only. Although
                  we have made every effort to display the colours of products accurately, we cannot
                  guarantee that a computer or device's display of the colours accurately reflects the
                  colour of the products. Your product may vary slightly from those images. Also, the
                  packaging of the product may vary from that shown in images on the website.
                </li>
                <li>
                  Our acceptance of your order will take place when a confirmation email is sent to you, at
                  which point a contract will come into existence between you and us.
                </li>
                <li>
                  If we are unable to accept your order, we will inform you of this and will not charge you
                  for the product. This might be for one of a number of reasons including because the
                  product is out of stock, because of unexpected limits on our resources which we could
                  not reasonably plan for, because we have identified an error in the price or description
                  of the product, because of an issue relating to the product or because we are unable to
                  meet a delivery deadline you have specified.
                </li>
                <li>
                  We will assign an order number to your order and tell you what it is when we accept your
                  order. It will help us if you can tell us the order number whenever you contact us about
                  your order.
                </li>
              </ul>
            </Section>

            <Section title="3. Registration">
              <ul className="list-disc list-inside space-y-2">
                <li>
                  You can register by filling out the register form and choosing a user name and password.
                  To register, you must provide your real name, address, phone number/mobile phone
                  number, and e-mail address.
                </li>
                <li>You must be Fifteen or older to register on the Site.</li>
                <li>Registration is free of any charges.</li>
                <li>
                  You may be required to register with the Site. You agree to keep your password
                  confidential and will be responsible for all use of your account and password. We reserve
                  the right to remove, reclaim, or change a username you select if we determine, in our
                  sole discretion, that such username is inappropriate, obscene, or otherwise
                  objectionable.
                </li>
              </ul>
            </Section>

            <Section title="4. Your responsibilities">
              <ul className="list-disc list-inside space-y-2">
                <li>
                  You accept responsibility for all activities that occur under your account or password.
                  You are solely responsible for maintaining the security of your password.
                </li>
                <li>
                  You warrant that the information you provide during the registration process (and any
                  notification of change of such information) is true and correct.
                </li>
                <li>You warrant that you are over 15 years old.</li>
              </ul>
            </Section>

            {/* Continue adding other sections in a similar way */}

            <Section title="19. Contact Us">
              <p>
                In order to resolve a complaint regarding the Site or to receive further information
                regarding use of the Site, please contact us at:
                <br />
                Dan’G Group of Companies Limited
                <br />
                NTYUKA KIJIWENI
                <br />
                P.o Box 372
                <br />
                Dodoma
                <br />
                Tel: +255 625 377 978
                <br />
                Email: danggroupplc@gmail.com
              </p>
              <p>All the product prices and delivery charges are displayed in Shillings (TSH).</p>
            </Section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Section = ({ title, children }) => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <div className="text-gray-700 leading-relaxed">{children}</div>
  </div>
);

export default TermsAndConditions;
