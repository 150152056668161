import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Button } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Link } from "react-router-dom";

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllSellers());
      })
      .catch((error) => {
        toast.error("Error deleting seller");
      });
  };

  const handleVerification = async (id, status) => {
  let url;
  
  if (status === "approved") {
    url = `${server}/shop/verify-business/${id}`;
  } else if (status === "rejected") {
    url = `${server}/shop/reject-business-verification/${id}`;
  } else if (status === "in_progress") {
    url = `${server}/shop/verify-business-in-progress/${id}`;
  } else if (status === "pending") {
    url = `${server}/shop/set-business-pending/${id}`; // Ensure your backend can handle this status change
  } else {
    url = `${server}/shop/reject-business-verification/${id}`;
  }

  try {
    await axios
      .patch(url, {}, { withCredentials: true })
      .then((res) => {
        toast.success(`Seller status changed to ${status} successfully!`);
        dispatch(getAllSellers()); // Refresh seller data after updating
      })
      .catch((error) => {
        toast.error("Error updating seller status");
      });
  } catch (error) {
    toast.error("Error updating seller status");
  }
};


  // Filter sellers by status and search query
  const filteredSellers = sellers
    ? sellers.filter((seller) => {
        const matchesSearchQuery =
          seller.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          seller.email.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus =
          statusFilter === "all" || seller.status === statusFilter;

        return matchesSearchQuery && matchesStatus;
      })
    : [];

  const columns = [
    { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Seller Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "joinedAt",
      headerName: "Joined At",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const status = params.row.status || "pending";

        const statusStyle = {
          approved: {
            dotColor: "bg-green-500",
            textColor: "text-green-600",
            bgColor: "bg-green-100",
          },
          rejected: {
            dotColor: "bg-red-500",
            textColor: "text-red-600",
            bgColor: "bg-red-100",
          },
          pending: {
            dotColor: "bg-yellow-500",
            textColor: "text-yellow-600",
            bgColor: "bg-yellow-100",
          },
          in_progress: {
            dotColor: "bg-blue-500",
            textColor: "text-blue-600",
            bgColor: "bg-blue-100",
          },
        };

        const currentStatus = statusStyle[status.toLowerCase()] || statusStyle["pending"];

        return (
          <div className={`flex items-center space-x-2`}>
            <span className={`h-2 w-2 ${currentStatus.dotColor} rounded-full`}></span>
            <span className={`${currentStatus.textColor} ${currentStatus.bgColor} px-3 py-1 rounded-full text-sm`}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          </div>
        );
      },
    },
    {
      field: "businessVerification",
      headerName: "Business Verification",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <select
              className="p-2 border rounded-md bg-white cursor-pointer"
              value={params.row.status} // Show the current status in the dropdown
              onChange={(e) => {
                const status = e.target.value;
                handleVerification(params.id, status); // Call the verification handler
              }}
              style={{ width: "150px", height: "40px", fontSize: "14px", textAlign: "center" }}
            >
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="pending">Pending</option>
              <option value="in_progress">In Progress</option>
            </select>
          </div>
        );
      },
    },
    {
      field: "preview",
      flex: 1,
      minWidth: 150,
      headerName: "Preview Shop",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/shop/preview/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "delete",
      flex: 1,
      minWidth: 150,
      headerName: "Delete Seller",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button onClick={() => setUserId(params.id) || setOpen(true)}>
            <AiOutlineDelete size={20} />
          </Button>
        </>
      ),
    },
  ];

  const row = [];
  filteredSellers.forEach((item) => {
    row.push({
      id: item._id,
      name: item?.name,
      email: item?.email,
      joinedAt: item.createdAt.slice(0, 10),
      address: item.address,
      status: item.status,
    });
  });

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2">All Sellers</h3>

        {/* Filter and Search */}
        <div className="flex justify-between mb-4">
          {/* Search bar */}
          <div className="flex items-center">
            <AiOutlineSearch size={20} className="mr-2" />
            <input
              type="text"
              placeholder="Search by Name or Email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>

          {/* Filter by Status */}
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="all">All</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
            <option value="in_progress">In Progress</option>
          </select>
        </div>

        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSellers;
