import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import tanzaniaCities from "../../constants/tanzaniaCities.json"; // Import the city names JSON

const OrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch, seller._id]);

  const data = orders && orders.find((item) => item._id === id);

  const orderUpdateHandler = async () => {
    await axios
      .put(
        `${server}/order/update-order-status/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        navigate("/dashboard-orders");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const refundOrderUpdateHandler = async () => {
    await axios
      .put(
        `${server}/order/order-refund-success/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className={`py-6 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between mb-8">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 text-[28px] font-semibold">Order Details</h1>
        </div>
        <Link to="/dashboard-orders">
          <div
            className={`${styles.button} !bg-[#fce1e6] !rounded-[4px] text-[#e94560] font-[600] !h-[45px] text-[18px]`}
          >
            Order List
          </div>
        </Link>
      </div>

      <div className="w-full flex flex-col md:flex-row justify-between mb-8">
        <h5 className="text-[#00000084] text-[20px]">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084] text-[20px]">
          Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      <div className="w-full mb-8">
        <h4 className="text-[22px] font-semibold mb-4">Order Items</h4>
        {data &&
          data.cart.map((item, index) => (
            <div
              key={index}
              className="w-full flex items-start mb-5 p-4 bg-gray-100 rounded-lg shadow-sm"
            >
              <img
                src={item.images[0]?.url}
                alt={item.name}
                className="w-[80px] h-[80px] object-cover mr-4 rounded-md"
              />
              <div className="w-full">
                <h5 className="text-[20px] font-medium">{item.name}</h5>
                <h5 className="text-[18px] text-[#00000091]">
                  TZS {item.discountPrice} x {item.qty}
                </h5>
              </div>
            </div>
          ))}
      </div>

      <div className="border-t w-full text-right pt-4 mb-8">
        <h5 className="text-[20px] font-medium">
          Total Price: <strong>TZS {data?.totalPrice}</strong>
        </h5>
      </div>

      <div className="w-full 800px:flex items-start justify-between mb-8">
        <div className="w-full 800px:w-[60%] mb-6 800px:mb-0">
          <h4 className="text-[22px] font-semibold mb-4">Shipping Address:</h4>
          <p className="text-[18px]">
            <strong>Address:</strong> {data?.shippingAddress.address1}{" "}
            {data?.shippingAddress.address2}
          </p>
          <p className="text-[18px]">
            <strong>Country:</strong> {data?.shippingAddress.country}
          </p>
          <p className="text-[18px]">
            <strong>City:</strong>{" "}
            {tanzaniaCities[data?.shippingAddress.city] ||
              data?.shippingAddress.city}
          </p>
          <p className="text-[18px]">
            <strong>Phone Number:</strong>+255 {data?.user?.phoneNumber}
          </p>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4 className="text-[22px] font-semibold mb-4">Payment Info:</h4>
          <p className="text-[18px]">
            <strong>Status:</strong> {data?.paymentInfo?.status || "Not Paid"}
          </p>
        </div>
      </div>

      <div className="w-full mb-8">
        <h4 className="text-[22px] font-semibold mb-4">Order Status:</h4>
        {data?.status !== "Processing refund" && data?.status !== "Refund Success" ? (
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-[250px] mt-2 border h-[45px] rounded-[5px] p-2 text-[18px]"
          >
            {[
              "Processing",
              "Transferred to delivery partner",
              "Shipping",
              "Received",
              "On the way",
              "Delivered",
            ]
              .slice(
                [
                  "Processing",
                  "Transferred to delivery partner",
                  "Shipping",
                  "Received",
                  "On the way",
                  "Delivered",
                ].indexOf(data?.status)
              )
              .map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              ))}
          </select>
        ) : (
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-[250px] mt-2 border h-[45px] rounded-[5px] p-2 text-[18px]"
          >
            {["Processing refund", "Refund Success"]
              .slice(
                ["Processing refund", "Refund Success"].indexOf(data?.status)
              )
              .map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              ))}
          </select>
        )}
      </div>

      <button
        className={`${styles.button} mt-5 !bg-[#FCE1E6] !rounded-[4px] text-[#E94560] font-semibold !h-[45px] text-[18px]`}
        onClick={data?.status !== "Processing refund" ? orderUpdateHandler : refundOrderUpdateHandler}
      >
        Update Status
      </button>
    </div>
  );
};

export default OrderDetails;
