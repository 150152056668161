import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header activeHeading={6} />
      <AboutContent />
      <Footer />
    </div>
  );
};

const AboutContent = () => {
  return (
    <div className={`${styles.section} my-8 px-4 sm:px-8 lg:px-16`}>
      <div className="text-center mb-12">
        <h2 className="text-5xl font-extrabold text-blue-800 mb-4">About Us</h2>
        <p className="text-lg text-gray-600">Learn more about Dan’g Group of Companies Limited</p>
      </div>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-8 shadow-lg rounded-lg">
        <div className="text-base text-gray-800 space-y-6">
          <p className="leading-relaxed">
            Dan’g Group of Companies Limited was incorporated on the 25th April 2023 in Brela, with incorporation
            NO. 165 214 285 under the Companies Act, 2002 and that the Company is Limited. The company is also
            registered with Tanzania Revenue Authority with TIN no 165-214-285,
            also the company registered on the Tanzania Meat Board as MEAT SUPPLIER on 20.05.2023 with
            Registration no.TMB.REG.MS.0002/07/2023, also the company registered With business license with
            B.L.NO.01695972023-2400002088 (business licensing Act no 25 of 1972),
            also The company registered on NATIONAL SOCIAL SECURITY with CERTIFICATE REGISTRATION NO
            1042932, Accordance with section 11 of the NSSF Act(Cap.50 R.E 2018).
          </p>
          <p className="leading-relaxed">
            Main interest in Livestock and the operations of the same began individually for both shareholders
            however, the operations of the actual entity began in December 2023. The company is going ahead in
            completing compliance to regulatory authorities within Tanzania such as OSHA, NEMC, TBS. All
            regulatory authorities have conducted frequent inspections to ensure that the ongoing operations at the
            farm align with the requirements of the laws, regulations, policies, and procedures.
          </p>
          <h3 className="text-3xl font-semibold text-blue-700 mt-8">Vision Statement</h3>
          <p className="leading-relaxed">
            Our Vision is to become the best quality livestock production industry in Tanzania supported by the best
            meat-producing community in sub-Saharan Africa. To produce meat products, dairy
            products, delivering, and shipping our Food products.
          </p>
          <h3 className="text-3xl font-semibold text-blue-700 mt-8">Mission Statement</h3>
          <p className="leading-relaxed">
            Under Dan’g Group of Companies Limited (DUPAZ BUTCHER) is a specialty butcher shop that seeks to
            serve the highest quality meats, cut to customer specifications, and become the foremost specialty
            meats provider, also to produce a livestock awareness that will be recognized internationally.
          </p>
          <h3 className="text-3xl font-semibold text-blue-700 mt-8">Objectives</h3>
          <ul className="list-disc pl-5 space-y-2 leading-relaxed">
            <li>Carry on the business of Livestock Rearing, Fattening, Transport, Packaging, and supplying meat to stakeholders.</li>
            <li>Support smallholder Livestock Keepers’ activities for quality-grade livestock.</li>
            <li>To train and create market linkages for smallholder livestock keepers.</li>
          </ul>
          <h3 className="text-3xl font-semibold text-blue-700 mt-8">Project Location and Physical Address</h3>
          <p className="leading-relaxed">
            The project is located on Plot No. 123, Block F, and House NO. 12, Madukani/Jamal Street, Hospital Road, Dodoma City.
          </p>
          <h3 className="text-3xl font-semibold text-blue-700 mt-8">Contact Us</h3>
          <p className="leading-relaxed">
            For more information or inquiries, feel free to contact us at: 
            <a href="mailto:info@danggroup.ltd" className="text-red-700 hover:underline ml-1">info@danggroup.ltd</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
