import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import { getAllProducts } from "../redux/actions/product";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const dispatch = useDispatch();
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    // Attempt to load products from localStorage
    const cachedProducts = localStorage.getItem("allProducts");

    if (cachedProducts) {
      try {
        const parsedProducts = JSON.parse(cachedProducts);
        dispatch({
          type: "SET_PRODUCTS",
          payload: parsedProducts,
        });
      } catch (error) {
        console.error("Failed to parse products from localStorage:", error);
        // If parsing fails, fetch from server
        dispatch(getAllProducts()).then((products) => {
          if (products) {
            localStorage.setItem("allProducts", JSON.stringify(products));
          }
        });
      }
    } else {
      // Fetch products from server if not available in localStorage
      dispatch(getAllProducts()).then((products) => {
        if (products) {
          localStorage.setItem("allProducts", JSON.stringify(products));
        }
      });
    }
  }, [dispatch]);

  // Memoize the filtered products data
  const filteredProducts = useMemo(() => {
    if (!allProducts || !Array.isArray(allProducts)) {
      return [];
    }
    if (!categoryData) {
      return allProducts;
    }
    return allProducts.filter((product) => product.category === categoryData);
  }, [allProducts, categoryData]);

  // Early return if loading
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Products - {categoryData ? categoryData : "All Products"}</title>
        <meta
          subheading="description"
          content="Browse our wide range of products across various categories."
        />
        <meta name="keywords" content="products, shop, ecommerce, categories" />
        <link rel="canonical" href="/products" />
      </Helmet>
      <div>
        <Header activeHeading={3} />
        <div className={`${styles.section} mt-10`}>
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {filteredProducts.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </div>
          {filteredProducts.length === 0 && (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No products Found!
            </h1>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductsPage;
