import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineMoneyCollect, AiOutlineSearch, AiOutlineCheckCircle } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import { Button, MenuItem, Select, Modal } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";

const StatusIndicator = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case "Delivered":
        return "bg-green-100 text-green-700";
      case "Processing":
        return "bg-yellow-100 text-yellow-700";
      case "On the way":
        return "bg-blue-100 text-blue-700";
      case "Received":
        return "bg-purple-100 text-purple-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <div className={`inline-flex items-center px-2 py-1 rounded-full text-sm font-medium ${getStatusStyle(status)}`}>
      <span className="w-2 h-2 rounded-full mr-2 bg-current"></span>
      {status}
    </div>
  );
};

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  const [filter, setFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
    dispatch(getAllProductsShop(seller._id));

    // Show modal if business is not verified
    if (!seller.isBusinessVerified && seller.status !== "approved") {
      setOpenModal(true);
    }
  }, [dispatch, seller._id, seller.isBusinessVerified]);

  const availableBalance = seller?.availableBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredOrders = orders
    ?.filter(order => filter === "All" || order.status === filter)
    ?.filter(order => 
      order._id.toLowerCase().includes(searchQuery.toLowerCase()) || 
      order.user?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const columns = [
    { 
      field: "id", 
      headerName: "Order ID", 
      minWidth: 150, 
      flex: 0.7,
      headerClassName: "bg-gray-100 text-gray-800",
    },
    {
      field: "userName",
      headerName: "Customer Name",
      minWidth: 200,
      flex: 1,
      headerClassName: "bg-gray-100 text-gray-800",
    },
    {
      field: "status",
      headerName: (
        <div className="flex items-center">
          Status
          <Select
            value={filter}
            onChange={handleFilterChange}
            displayEmpty
            className="ml-2 p-1 border border-gray-300 rounded-md"
            style={{ fontSize: '14px', height: '28px' }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Processing">Processing</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
            <MenuItem value="On the way">On the way</MenuItem>
            <MenuItem value="Received">Received</MenuItem>
          </Select>
        </div>
      ),
      minWidth: 200,
      flex: 0.7,
      renderCell: (params) => {
        return <StatusIndicator status={params.value} />;
      },
      headerClassName: "bg-gray-100 text-gray-800",
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
      headerClassName: "bg-gray-100 text-gray-800",
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
      headerClassName: "bg-gray-100 text-gray-800",
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = filteredOrders?.map((item) => ({
    id: item._id,
    userName: item.user?.name || "N/A",
    itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
    total: "TZS " + item.totalPrice.toLocaleString(),
    status: item.status,
  })) || [];

  // Determine the verification status and its associated styles
  const verificationStatus = seller.isBusinessVerified ? "Verified" : seller.status === "rejected" ? "Rejected" : "Pending";
  const statusStyle = {
    verified: { text: "Verified", color: "text-green-600", icon: <AiOutlineCheckCircle className="mr-1" /> },
    rejected: { text: "Rejected", color: "text-red-600", icon: <AiOutlineCheckCircle className="mr-1" /> },
    pending: { text: "Pending", color: "text-yellow-600", icon: <AiOutlineCheckCircle className="mr-1" /> }
  }[verificationStatus.toLowerCase()] || {};

  return (
    <div className="w-full p-4 lg:p-8">
      <h3 className="text-2xl font-semibold pb-2 flex items-center">
        Overview
        <span className={`ml-2 flex items-center ${statusStyle.color}`}>
          {statusStyle.icon}
          {statusStyle.text}
        </span>
      </h3>

      {/* Show warning message if status is Pending or Rejected */}
      {(verificationStatus === "Pending" || verificationStatus === "Rejected") && (
        <div className="bg-yellow-100 text-yellow-700 p-4 rounded-md mb-4 flex items-center justify-between">
          <p>
            Your business is currently <strong>{verificationStatus}</strong>. You will not be able to withdraw funds until the business verification process is completed.
          </p>
          <Link to="/business-verification" className="text-blue-600 underline">
            Complete Verification
          </Link>
        </div>
      )}

      {/* Account Balance, Orders, and Products Cards */}
      <div className="w-full block lg:flex items-center justify-between mb-6">
        {/* Balance Card */}
        <div className="w-full mb-4 lg:mb-0 lg:w-1/3 min-h-[20vh] bg-white shadow-md rounded-lg p-4">
          <div className="flex items-center mb-2">
            <AiOutlineMoneyCollect size={30} className="mr-2 text-gray-500" />
            <h3 className="text-lg font-medium text-gray-500">
              Account Balance <span className="text-base font-normal">(with 10% service charge)</span>
            </h3>
          </div>
          <h5 className="text-2xl font-semibold">TZS {availableBalance}</h5>
          <Link to="/dashboard-withdraw-money" className="text-blue-600 hover:underline">
            Withdraw Money
          </Link>
        </div>

        {/* Orders Card */}
        <div className="w-full mb-4 lg:mb-0 lg:w-1/3 min-h-[20vh] bg-white shadow-md rounded-lg p-4">
          <div className="flex items-center mb-2">
            <MdBorderClear size={30} className="mr-2 text-gray-500" />
            <h3 className="text-lg font-medium text-gray-500">All Orders</h3>
          </div>
          <h5 className="text-2xl font-semibold">{orders?.length}</h5>
          <Link to="/dashboard-orders" className="text-blue-600 hover:underline">
            View Orders
          </Link>
        </div>

        {/* Products Card */}
        <div className="w-full lg:w-1/3 min-h-[20vh] bg-white shadow-md rounded-lg p-4">
          <div className="flex items-center mb-2">
            <AiOutlineMoneyCollect size={30} className="mr-2 text-gray-500" />
            <h3 className="text-lg font-medium text-gray-500">All Products</h3>
          </div>
          <h5 className="text-2xl font-semibold">{products?.length}</h5>
          <Link to="/dashboard-products" className="text-blue-600 hover:underline">
            View Products
          </Link>
        </div>
      </div>

      <h3 className="text-2xl font-semibold pb-2">Latest Orders...</h3>

      <div className="w-full mb-4 flex flex-col lg:flex-row items-center">
        <div className="relative w-full lg:w-2/3 mb-4 lg:mb-0">
          <AiOutlineSearch className="absolute top-3 left-3 text-gray-500" size={20} />
          <input
            type="text"
            placeholder="Search by Order ID or Customer Name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="w-full min-h-[45vh] bg-white rounded-lg shadow-md p-4">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
          className="border-0"
          componentsProps={{
            row: {
              className: "hover:bg-gray-50",
            },
          }}
          sx={{
            '& .MuiDataGrid-row': {
              padding: '10px 0',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '2px solid #e5e7eb',
              backgroundColor: '#f9fafb',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #e5e7eb',
            },
          }}
        />
      </div>

      {/* Modal for Unverified Business */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="bg-white w-[90%] lg:w-[40%] mx-auto my-20 p-8 rounded-lg shadow-lg relative">
          <h2 className="text-2xl font-bold mb-4 text-center">Business Verification Required</h2>
          <p className="mb-6 text-center text-gray-700">
            To access all features and sell products on our platform, your business needs to be verified.
          </p>
          <div className="flex justify-center space-x-4">
            <Link to="/business-verification" className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-200">
              Verify Now
            </Link>
            <Button
              onClick={() => setOpenModal(false)}
              className="text-blue-600 border border-blue-600 px-6 py-2 rounded-md hover:bg-gray-100 transition duration-200"
            >
              Remind Me Later
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardHero;
