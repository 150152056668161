import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    if (allProducts && data) {
      const filteredProducts = allProducts.filter(
        (product) => product.category === data.category && product._id !== data._id
      );
      setProductData(filteredProducts);
    }
  }, [allProducts, data]);

  return (
    <div className="bg-white py-8">
      {data ? (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-semibold text-gray-800 border-b-2 border-gray-200 mb-6">
            Related Products
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {productData && productData.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;
