import React from "react";
import styles from "../styles/styles";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const ReturnPolicy = () => {
  return (
    <>
      <Header activeHeading={5} />
      <div className={`${styles.section} mt-10`}>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-center mb-8">Return Policy</h1>
          <div className="space-y-6">
            <Section title="DAN’G GROUP OF COMPANIES LIMITED, RETURN POLICY">
              <p>
                Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for the reasons detailed in the terms and conditions, you may return it to us for [a full refund/store credit /an exchange/a full refund or store credit/for a full refund or an exchange/for store credit or an exchange/for a full refund, store credit, or an exchange] only].
              </p>
              <p>Please see below for more information on our return policy.</p>
            </Section>

            <Section title="Returns">
              <p>
                All returns must be postmarked within five (5) days of the purchase date. All returned items must be in new and unused condition, with all original tags and labels attached.
              </p>
            </Section>

            <Section title="Return Process">
              <p>
                To return an item, please return it to the pickup location where you collected it.
              </p>
              <p>
                Please note you will be responsible for all return shipping charges. We strongly recommend that you use a trackable method to mail your return. You may also use the prepaid shipping label enclosed with your package.
              </p>
            </Section>

            <Section title="Refunds">
              <p>
                After receiving your return and inspecting the condition of your item, we will process your return/exchange/return or exchange. Please allow us at least 3 days to process refunds from the receipt of your item. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.
              </p>
              <p>
                We will notify you by email when your return has been processed.
              </p>
            </Section>

            <Section title="Questions">
              <p>
                If you have any questions concerning our return policy, please contact us at:
              </p>
              <p>
                Phone: +255 625 377 978 <br />
                +255 678 227 227 <br />
                Email: danggroupplc@gmail.com
              </p>
            </Section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Section = ({ title, children }) => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <div className="text-gray-700 leading-relaxed">{children}</div>
  </div>
);

export default ReturnPolicy;
