import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillEnvironment,
} from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import Logo from '../../../src/Assests/images/logo.jpg';

const Footer = () => {
  return (
    <div className="bg-[#000] text-white pt-12">
      {/* Subscription Section */}
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#342ac8] py-8">
        <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5 text-center md:text-left">
          <span className="text-red-700">Subscribe</span> to get the latest news, events, and offers
        </h1>
        <div className="flex flex-col md:flex-row items-center">
          <input
            type="text"
            required
            placeholder="Enter your email..."
            className="text-gray-800 sm:w-72 w-full sm:mr-5 mr-1 mb-4 md:mb-0 py-3 rounded px-2 focus:outline-none"
          />
          <button className="bg-red-700 hover:bg-red-800 duration-300 px-5 py-3 rounded-md text-white w-full md:w-auto">
            Submit
          </button>
        </div>
      </div>

      {/* Footer Columns */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 px-8 py-12 text-center sm:text-left bg-black">
        
        {/* Company Column */}
        <div className="flex flex-col items-center sm:items-start">
          <img
            src={Logo}
            alt="Logo"
            className="w-24 mb-6"
            style={{ filter: "brightness(0) invert(1)" }}
          />
          
          <div className="mb-6">
            <h1 className="font-bold text-lg mb-4">Offices</h1>
            <div className="flex items-center mb-2">
              <AiFillEnvironment className="text-red-700 mr-2" />
              <p>Dodoma HQ: +255 678 227 227</p>
            </div>
            <div className="flex items-center mb-2">
              <AiFillEnvironment className="text-red-700 mr-2" />
              <p>Dar Es Salaam: +255 625 377 978</p>
            </div>
          </div>

          <p className="text-gray-400 mb-4">Contact us: <a href="mailto:info@danggroup.ltd" className="text-red-700 hover:underline">info@danggroup.ltd</a></p>

          <div className="flex items-center space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61559134603943" target="_blank" rel="noopener noreferrer">
              <AiFillFacebook size={25} className="cursor-pointer text-gray-400 hover:text-white" />
            </a>
            <a href="https://x.com/farajadm?s=11" target="_blank" rel="noopener noreferrer">
              <FaXTwitter size={25} className="cursor-pointer text-gray-400 hover:text-white" />
            </a>
            <a href="https://www.instagram.com/officialdanggroup?igsh=aHl0amd3aDBoYnVi&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram size={25} className="cursor-pointer text-gray-400 hover:text-white" />
            </a>
            <a href="https://www.linkedin.com/company/dang-group-ltd/" target="_blank" rel="noopener noreferrer">
              <AiFillLinkedin size={25} className="cursor-pointer text-gray-400 hover:text-white" />
            </a>
          </div>
        </div>

        {/* Company Links */}
        <div>
          <h1 className="mb-6 font-bold text-lg">Company</h1>
          <ul className="space-y-3">
            {footerProductLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-red-700 duration-300 text-sm cursor-pointer"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Shop Links */}
        <div>
          <h1 className="mb-6 font-bold text-lg">Shop</h1>
          <ul className="space-y-3">
            {footercompanyLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-red-700 duration-300 text-sm cursor-pointer"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Support Links */}
        <div>
          <h1 className="mb-6 font-bold text-lg">Support</h1>
          <ul className="space-y-3">
            {footerSupportLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-red-700 duration-300 text-sm cursor-pointer"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="border-t border-gray-700 py-8 bg-black">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-center text-gray-400 text-sm">
          <span>© 2024 DAN'G GROUP PLC. All rights reserved.</span>
          <span>Terms · Privacy Policy</span>
          <div className="flex justify-center">
            <img
              src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
              alt="Payment Methods"
              className="w-full max-w-[200px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
