import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Footer from "../components/Layout/Footer";
import { getAllProducts } from "../redux/actions/product";

const BestSellingPage = () => {
  const dispatch = useDispatch();
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    const cachedProducts = localStorage.getItem("allProducts");

    if (cachedProducts) {
      try {
        const parsedProducts = JSON.parse(cachedProducts);
        dispatch({
          type: "SET_PRODUCTS",
          payload: parsedProducts,
        });
      } catch (error) {
        console.error("Failed to parse products from localStorage:", error);
        // Fetch from server if parsing fails
        dispatch(getAllProducts()).then((products) => {
          if (products) {
            localStorage.setItem("allProducts", JSON.stringify(products));
          }
        });
      }
    } else {
      // Fetch products from server if not available in localStorage
      dispatch(getAllProducts()).then((products) => {
        if (products) {
          localStorage.setItem("allProducts", JSON.stringify(products));
        }
      });
    }
  }, [dispatch]);

  // Memoize the sorted data to prevent unnecessary recomputation
  const sortedData = useMemo(() => {
    if (!allProducts || !Array.isArray(allProducts)) return [];
    return [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
  }, [allProducts]);

  // Early return if loading
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header activeHeading={2} />
      <div className={`${styles.section} mt-10`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {sortedData.map((product, index) => (
            <ProductCard data={product} key={index} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BestSellingPage;
