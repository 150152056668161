import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { getAllOrdersOfUser } from "../redux/actions/order";
import { server } from "../server";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import tanzaniaCities from "../constants/tanzaniaCities.json"; // Import city mapping

const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async () => {
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
        setComment("");
        setRating(1);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const refundHandler = async () => {
    await axios
      .put(`${server}/order/order-refund/${id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between mb-6">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 text-[25px]">Order Details</h1>
        </div>
      </div>

      <div className="w-full flex items-center justify-between">
        <h5 className="text-[#00000084]">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084]">
          Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* Order Items */}
      <div className="my-8">
        {data &&
          data?.cart.map((item, index) => (
            <div
              className="w-full flex items-start mb-5 p-4 bg-gray-100 rounded-lg shadow-sm"
              key={index}
            >
              <img
                src={`${item.images[0]?.url}`}
                alt=""
                className="w-[80px] h-[80px] rounded-md"
              />
              <div className="w-full ml-4">
                <h5 className="text-[20px] font-semibold">{item.name}</h5>
                <h5 className="text-[18px] text-[#00000091]">
                  TZS {item.discountPrice} x {item.qty}
                </h5>
              </div>
              {!item.isReviewed && data?.status === "Delivered" && (
                <div
                  className={`${styles.button} text-white ml-4`}
                  onClick={() => {
                    setOpen(true);
                    setSelectedItem(item);
                  }}
                >
                  Write a Review
                </div>
              )}
            </div>
          ))}
      </div>

      {/* Review Popup */}
      {open && (
        <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="w-[50%] h-min bg-white shadow rounded-md p-4">
            <div className="w-full flex justify-end">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-[30px] font-[500] text-center">Give a Review</h2>
            <div className="w-full flex mt-4">
              <img
                src={`${selectedItem?.images[0]?.url}`}
                alt=""
                className="w-[80px] h-[80px] rounded-md"
              />
              <div className="ml-4">
                <h4 className="text-[20px]">{selectedItem?.name}</h4>
                <h4 className="text-[18px] text-[#00000091]">
                  TZS {selectedItem?.discountPrice} x {selectedItem?.qty}
                </h4>
              </div>
            </div>

            <div className="mt-6">
              <h5 className="text-[20px] font-[500]">
                Give a Rating <span className="text-red-500">*</span>
              </h5>
              <div className="flex w-full mt-2">
                {[1, 2, 3, 4, 5].map((i) =>
                  rating >= i ? (
                    <AiFillStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={25}
                      onClick={() => setRating(i)}
                    />
                  ) : (
                    <AiOutlineStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={25}
                      onClick={() => setRating(i)}
                    />
                  )
                )}
              </div>
              <textarea
                className="mt-4 w-full border p-2 rounded-md outline-none"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? Share your experience!"
                rows="5"
              />
              <div
                className={`${styles.button} mt-4 text-white`}
                onClick={rating > 1 ? reviewHandler : null}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Order Summary */}
      <div className="border-t w-full text-right pt-6">
        <h5 className="text-[18px]">
          Total Price: <strong>TZS {data?.totalPrice}</strong>
        </h5>
      </div>

      <div className="w-full 800px:flex items-start justify-between mt-8">
        <div className="w-full 800px:w-[60%]">
          <h4 className="text-[20px] font-[600]">Shipping Address:</h4>
          <p className="text-[18px] mt-2">
            <strong>Address:</strong> {data?.shippingAddress.address1}{" "}
            {data?.shippingAddress.address2}
          </p>
          <p className="text-[18px]">
            <strong>Country:</strong> {data?.shippingAddress.country}
          </p>
          <p className="text-[18px]">
            <strong>City:</strong>{" "}
            {tanzaniaCities[data?.shippingAddress.city] ||
              data?.shippingAddress.city}
          </p>
          <p className="text-[18px]">
            <strong>Phone Number:</strong>+255 {data?.user?.phoneNumber}
          </p>
        </div>
        <div className="w-full 800px:w-[40%] mt-6 800px:mt-0">
          <h4 className="text-[20px] font-[600]">Payment Info:</h4>
          <p className="text-[18px]">
            <strong>Status:</strong>{" "}
            {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </p>
          {data?.status === "Delivered" && (
            <div
              className={`${styles.button} mt-4 text-white`}
              onClick={refundHandler}
            >
              Request a Refund
            </div>
          )}
        </div>
      </div>

      <Link to="/">
        <div className={`${styles.button} mt-8 text-white`}>Send Message</div>
      </Link>
    </div>
  );
};

export default UserOrderDetails;
