import React from 'react';
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import CookieConsent from '../components/CookieConsent/CookieConsent';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Dan'g Group | Fresh and Healthy Food Farm Products</title>
        <meta
          name="description"
          content="Dan'g Group: Fresh and healthy food and farm products delivered to your doorstep. Shop with us online and enjoy fast, reliable delivery within Tanzania."
        />
        <meta
          name="keywords"
          content="food, farm products, fresh produce, organic food, healthy eating, delivery, Tanzania"
        />
        <meta name="author" content="Dan'g Group" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Dan'g Group | Fresh and Healthy Food Farm Products"
        />
        <meta
          property="og:description"
          content="Fresh and healthy food and farm products delivered to your doorstep. Shop with us online and enjoy fast, reliable delivery within Tanzania."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://www.danggroup.ltd" />
        <meta property="og:type" content="website" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dan'g Group | Fresh and Healthy Food Farm Products"
        />
        <meta
          name="twitter:description"
          content="Fresh and healthy food and farm products delivered to your doorstep. Shop with us online and enjoy fast, reliable delivery within Tanzania."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Dan'g Group",
              "url": "https://www.danggroup.ltd",
              "logo": "%PUBLIC_URL%/logo192.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+255 687 516 739",
                "contactType": "Customer Service",
                "areaServed": "TZ",
                "availableLanguage": "en"
              },
              "sameAs": [
                "https://www.facebook.com/danggroup",
                "https://www.instagram.com/officialdanggroup"
              ]
            }
          `}
        </script>
      </Helmet>
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      <FeaturedProduct />
      <Sponsored />
      <Footer />
      <CookieConsent />
    </div>
  );
};

export default HomePage;
