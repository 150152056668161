import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";

const ComingSoonPage = ({ pageName }) => {
  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen py-12 bg-gray-50 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">Coming Soon</h1>
          <p className="text-lg text-gray-500 mb-8">
            We're working hard to bring you the best experience. The {pageName} page will be available soon. Stay tuned!
          </p>
          <div className="flex justify-center space-x-4">
            <Link to="/">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                Go to Homepage
              </button>
            </Link>
            <Link to="/contact">
              <button className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoonPage;
