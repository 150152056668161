import React, { useState, useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    setActiveTab(activeTab === tab ? 0 : tab);
  };

  return (
    <div className={`${styles.section} my-8 px-4 sm:px-8 lg:px-16`}>
      <h2 className="text-4xl font-bold text-indigo-900 mb-8 text-center">Frequently Asked Questions</h2>
      <div className="mx-auto space-y-6 max-w-3xl">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="border border-indigo-200 p-4 rounded-lg transition-all duration-300 ease-in-out hover:bg-indigo-50 shadow-sm"
          >
            <button
              className="flex items-center justify-between w-full focus:outline-none"
              onClick={() => toggleTab(index + 1)}
            >
              <span className="text-lg font-medium text-indigo-900">{faq.question}</span>
              {activeTab === index + 1 ? (
                <svg className="h-6 w-6 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="h-6 w-6 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              )}
            </button>
            {activeTab === index + 1 && (
              <div className="mt-4 text-gray-700">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "How to order?",
    answer: (
      <ol className="list-decimal pl-5 text-base space-y-2">
        <li>Select the product and modify the quantity using the plus(+) and minus sign (-).</li>
        <li>Add the product to the cart.</li>
        <li>Cross-check all the products and modify the quantity before submitting.</li>
        <li>Confirm your order and submit.</li>
      </ol>
    ),
  },
  {
    question: "What's the minimum amount to order?",
    answer: (
      <p>
        The minimum amount for any order is from Tshs. 35,000/=. There is no maximum amount of order.
      </p>
    ),
  },
  {
    question: "How many times can I order in a day?",
    answer: (
      <p>
        There is no limit to the number of orders you can place, the order needs only to be from Tshs. 35,000/=
      </p>
    ),
  },
  {
    question: "Where do you deliver?",
    answer: (
      <p>
        We deliver our products across Tanzania.
      </p>
    ),
  },
  {
    question: "How long does it take to deliver the order?",
    answer: (
      <p>
        Our minimum time for an order to be delivered is within four (4) hours in Dar es Salaam,
        (1) hour in Dodoma town, (2) hours in Arusha, (2) hours in Mbeya, (2) hours in Mwanza,
        (2) hours in Iringa, (2) hours in Zanzibar, (1) hour in Morogoro.
      </p>
    ),
  },
  {
    question: "How do I handle wrongly delivered orders and defective products?",
    answer: (
      <p>
        Contact our call centre via +255 678 227 227 / +255 625 377 978 for all orders that are
        wrongly delivered to you and for medicines that have defects.
      </p>
    ),
  },
  {
    question: "How do I pay for my order?",
    answer: (
      <p>
        After submitting the order proceed to payments options and choose your preferred
        payment choice.
      </p>
    ),
  },
  {
    question: "Which payment options are available?",
    answer: (
      <p>
        We accept payment via mobile money M-PESA, TIGOPESA, HALOPESA, AIRTEL
        MONEY, T-PESA, banks CRDB, NMB, BOA BANK, ABSA, EQUITY, UBA, or cash on
        delivery.
      </p>
    ),
  },
];

export default FAQPage;
