import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";

const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-10 bg-black bg-opacity-50">
      <div className="fixed top-0 right-0 h-full w-[85%] sm:w-[60%] md:w-[40%] lg:w-[30%] bg-white flex flex-col overflow-y-auto justify-between shadow-lg">
        {cart && cart.length === 0 ? (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex justify-end pt-5 pr-5 absolute top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5 className="text-lg font-medium">Your cart is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item count */}
              <div className="flex items-center p-4 border-b">
                <IoBagHandleOutline size={25} />
                <h5 className="pl-2 text-[20px] font-medium">
                  {cart && cart.length} items
                </h5>
              </div>

              {/* Cart Items */}
              <div className="space-y-4">
                {cart &&
                  cart.map((item, index) => (
                    <CartSingle
                      key={index}
                      data={item}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 mb-4">
              {/* Checkout Button */}
              <Link to="/checkout">
                <div className="h-[50px] flex items-center justify-center w-full bg-red-500 rounded-lg text-center">
                  <h1 className="text-white text-[18px] font-semibold">
                    Checkout Now (TZS {totalPrice.toLocaleString()})
                  </h1>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = () => {
    if (data.stock <= value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      const updatedCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updatedCartData);
    }
  };

  const decrement = () => {
    if (value > 1) {
      setValue(value - 1);
      const updatedCartData = { ...data, qty: value - 1 };
      quantityChangeHandler(updatedCartData);
    }
  };

  return (
    <div className="border rounded-lg p-4 flex items-center space-x-4 bg-white shadow-sm">
      <div className="flex flex-col items-center">
        <button
          className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
          onClick={increment}
        >
          <HiPlus size={18} />
        </button>
        <span className="py-2 text-lg">{value}</span>
        <button
          className="bg-gray-300 text-black rounded-full w-8 h-8 flex items-center justify-center"
          onClick={decrement}
        >
          <HiOutlineMinus size={18} />
        </button>
      </div>
      <img
        src={data?.images[0]?.url}
        alt={data.name}
        className="w-[80px] h-[80px] object-cover rounded-lg"
      />
      <div className="flex-grow">
        <h1 className="text-lg font-medium leading-tight">{data.name}</h1>
        <h4 className="text-gray-500 text-sm">
          TZS {data.discountPrice.toLocaleString()} * {value}
        </h4>
        <h4 className="text-red-500 font-semibold text-lg">
          TZS {totalPrice.toLocaleString()}
        </h4>
      </div>
      <RxCross1
        className="cursor-pointer text-gray-500"
        onClick={() => removeFromCartHandler(data)}
      />
    </div>
  );
};

export default Cart;
