import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineEye,
  AiOutlineMessage,
  AiOutlineShoppingCart,
  AiOutlineClose,
} from "react-icons/ai";
import { LocationOn } from "@mui/icons-material"; // Import the LocationOn icon
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import axios from "axios";
import Ratings from "../../Products/Ratings";
import { server } from "../../../server";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data._id]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  return (
    <>
      <div className="w-full sm:max-w-xs lg:max-w-sm bg-white rounded-lg shadow-md p-3 relative cursor-pointer transition-transform transform hover:scale-105 hover:shadow-lg mx-auto">
        <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt={data.name}
            className="w-full h-36 object-cover rounded-lg mb-3"
          />
        </Link>
        <div className="p-2">
          <Link to={`/shop/preview/${data?.shop._id}`}>
            <h5 className="text-base font-bold text-blue-600 mb-1">{data.shop.name}</h5>
          </Link>
          <div className="flex items-center text-gray-600 mb-2"> {/* Updated this line */}
            <LocationOn style={{ color: "red", marginRight: "4px" }} /> {/* Location icon with red color */}
            <p className="text-sm">{data.shop.address}</p>
          </div>
          <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
            <h4 className="text-sm font-semibold text-gray-800 mb-2">
              {data.name.length > 40 ? `${data.name.slice(0, 40)}...` : data.name}
            </h4>
          </Link>
          <div className="flex flex-col mb-2">
            {data.originalPrice && (
              <h4 className="text-sm text-red-500 line-through mb-1">
                {`TZS ${data.originalPrice.toLocaleString()}`}
              </h4>
            )}
            <h5 className="text-lg font-bold text-blue-800">
              {`TZS ${data.discountPrice.toLocaleString()}`}
            </h5>
          </div>
          <span className="text-xs font-medium text-green-600 mb-2">
            {data?.sold_out} sold
          </span>
          <div className="flex items-center justify-center mb-2">
            <Ratings rating={data?.ratings} />
          </div>
        </div>
        <div className="absolute top-2 right-2 flex flex-col space-y-2">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer text-red-500"
              onClick={() => removeFromWishlistHandler(data)}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer text-black"
              onClick={() => addToWishlistHandler(data)}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer text-black"
            onClick={() => setOpen(true)}
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer text-black"
            onClick={() => addToCartHandler(data._id)}
            title="Add to cart"
          />
          <AiOutlineMessage
            size={25}
            className="cursor-pointer text-black"
            onClick={handleMessageSubmit}
            title="Send Message"
          />
        </div>
      </div>

      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-1/2 p-5 relative">
            <button
              className="absolute top-2 right-2"
              onClick={() => setOpen(false)}
            >
              <AiOutlineClose size={25} />
            </button>
            <div className="flex flex-col md:flex-row">
              <img
                src={data.images && data.images[0]?.url}
                alt={data.name}
                className="w-full md:w-1/2 h-48 object-cover rounded-lg mb-4 md:mb-0"
              />
              <div className="md:ml-4 flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl font-bold text-gray-800">{data.name}</h2>
                  <p className="text-sm text-gray-600 mt-2">{data.description}</p>
                  <div className="flex items-center mt-3">
                    <span className="text-red-500 line-through text-lg">
                      {data.originalPrice && `TZS ${data.originalPrice.toLocaleString()}`}
                    </span>
                    <span className="text-lg font-bold text-blue-800 ml-3">
                      {`TZS ${data.discountPrice.toLocaleString()}`}
                    </span>
                  </div>
                  <span className="text-xs font-medium text-green-600 mt-2">
                    {data?.sold_out} sold
                  </span>
                  <div className="flex items-center mt-2">
                    <Ratings rating={data?.ratings} />
                  </div>
                </div>
                <div className="flex space-x-4 mt-5">
                  <button
                    className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition"
                    onClick={() => addToCartHandler(data._id)}
                  >
                    Add to Cart
                  </button>
                  <button
                    className="bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition"
                    onClick={handleMessageSubmit}
                  >
                    Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;
