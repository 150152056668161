import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist, dispatch]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  return (
    <div className="bg-white">
      {data ? (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="lg:flex lg:space-x-8">
            <div className="lg:w-1/2">
              <img
                src={`${data && data.images[select]?.url}`}
                alt={data.name}
                className="w-full h-96 object-cover rounded-lg"
              />
              <div className="flex mt-4 space-x-2">
                {data &&
                  data.images.map((i, index) => (
                    <img
                      key={index}
                      src={`${i?.url}`}
                      alt=""
                      className={`w-20 h-20 object-cover rounded cursor-pointer ${select === index ? "border-2 border-indigo-500" : ""}`}
                      onClick={() => setSelect(index)}
                    />
                  ))}
              </div>
            </div>
            <div className="mt-6 lg:mt-0 lg:w-1/2">
              <h1 className="text-3xl font-bold text-gray-800">{data.name}</h1>
              <p className="mt-4 text-gray-600">{data.description}</p>
              <div className="flex items-center mt-6">
                <span className="text-2xl font-bold text-gray-800">TZS {data.discountPrice}</span>
                {data.originalPrice && (
                  <span className="ml-4 text-xl text-gray-500 line-through">
                    TZS {data.originalPrice}
                  </span>
                )}
              </div>
              <div className="flex items-center mt-4">
                <div className="flex items-center">
                  <button
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-l hover:bg-gray-300"
                    onClick={decrementCount}
                  >
                    -
                  </button>
                  <span className="px-4 py-2 bg-white text-gray-800 border-t border-b">
                    {count}
                  </span>
                  <button
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-r hover:bg-gray-300"
                    onClick={incrementCount}
                  >
                    +
                  </button>
                </div>
                <button
                  className="ml-4 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 flex items-center"
                  onClick={() => addToCartHandler(data._id)}
                >
                  Add to cart <AiOutlineShoppingCart className="ml-1" />
                </button>
              </div>
              <div className="flex items-center mt-4">
                {click ? (
                  <AiFillHeart
                    size={30}
                    className="cursor-pointer text-red-500"
                    onClick={() => removeFromWishlistHandler(data)}
                  />
                ) : (
                  <AiOutlineHeart
                    size={30}
                    className="cursor-pointer text-gray-800"
                    onClick={() => addToWishlistHandler(data)}
                  />
                )}
              </div>
              <div className="flex items-center mt-6">
                <Link to={`/shop/preview/${data?.shop._id}`} className="flex items-center">
                  <img
                    src={`${data?.shop?.avatar?.url}`}
                    alt={data.shop.name}
                    className="w-12 h-12 object-cover rounded-full mr-3"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">{data.shop.name}</h3>
                    <div className="flex items-center">
                      <span className="text-gray-600 mr-2">({averageRating}/5)</span>
                      <Ratings rating={data?.ratings} />
                    </div>
                  </div>
                </Link>
                <button
                  className="ml-auto px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900 flex items-center"
                  onClick={handleMessageSubmit}
                >
                  Send Message <AiOutlineMessage className="ml-1" />
                </button>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
        </div>
      ) : (
        <div className="text-center py-16">
          <h2 className="text-2xl font-semibold text-gray-800">Product not found</h2>
        </div>
      )}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-gray-100 px-4 sm:px-6 lg:px-8 py-8 mt-8 rounded-lg">
      <div className="flex justify-between border-b-2 border-gray-200">
        <button
          className={`text-lg font-medium pb-2 ${active === 1 ? "border-b-2 border-indigo-600 text-indigo-600" : "text-gray-600"}`}
          onClick={() => setActive(1)}
        >
          Product Details
        </button>
        <button
          className={`text-lg font-medium pb-2 ${active === 2 ? "border-b-2 border-indigo-600 text-indigo-600" : "text-gray-600"}`}
          onClick={() => setActive(2)}
        >
          Product Reviews
        </button>
        <button
          className={`text-lg font-medium pb-2 ${active === 3 ? "border-b-2 border-indigo-600 text-indigo-600" : "text-gray-600"}`}
          onClick={() => setActive(3)}
        >
          Seller Information
        </button>
      </div>
      {active === 1 && (
        <div className="py-4">
          <p className="text-lg text-gray-700 whitespace-pre-line">{data.description}</p>
        </div>
      )}
      {active === 2 && (
        <div className="py-4">
          {data.reviews.length > 0 ? (
            data.reviews.map((item, index) => (
              <div key={index} className="flex my-4">
                <img
                  src={`${item.user.avatar?.url}`}
                  alt={item.user.name}
                  className="w-12 h-12 rounded-full"
                />
                <div className="ml-4">
                  <div className="flex items-center">
                    <h3 className="font-medium">{item.user.name}</h3>
                    <Ratings rating={item.rating} className="ml-2" />
                  </div>
                  <p className="mt-2">{item.comment}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-lg text-gray-600">No reviews for this product.</p>
          )}
        </div>
      )}
      {active === 3 && (
        <div className="py-4 lg:flex lg:space-x-8">
          <div className="lg:w-1/2">
            <Link to={`/shop/preview/${data.shop._id}`} className="flex items-center">
              <img
                src={`${data.shop.avatar?.url}`}
                alt={data.shop.name}
                className="w-16 h-16 rounded-full"
              />
              <div className="ml-4">
                <h3 className="text-lg font-semibold">{data.shop.name}</h3>
                <p className="text-gray-600">({averageRating}/5) Ratings</p>
              </div>
            </Link>
            <p className="mt-4 text-lg text-gray-700">{data.shop.description}</p>
          </div>
          <div className="mt-6 lg:mt-0 lg:w-1/2 lg:flex lg:flex-col lg:items-end">
            <div className="text-left">
              <p className="text-lg font-medium">
                Joined on: <span className="font-normal">{data.shop.createdAt?.slice(0, 10)}</span>
              </p>
              <p className="mt-2 text-lg font-medium">
                Total Products: <span className="font-normal">{products.length}</span>
              </p>
              <p className="mt-2 text-lg font-medium">
                Total Reviews: <span className="font-normal">{totalReviewsLength}</span>
              </p>
              <Link to={`/shop/${data.shop._id}`}>
                <button className="mt-4 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700">
                  Visit Shop
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
