import { Button, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";

const StatusIndicator = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case "Delivered":
        return "bg-green-100 text-green-700";
      case "Processing":
        return "bg-yellow-100 text-yellow-700";
      case "On the way":
        return "bg-blue-100 text-blue-700";
      case "Received":
        return "bg-purple-100 text-purple-700";
      case "Refund Success":
        return "bg-gray-100 text-gray-700";
      case "Transferred to delivery partner":
        return "bg-gray-100 text-gray-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <div className={`inline-flex items-center px-2 py-1 rounded-full text-sm font-medium ${getStatusStyle(status)}`}>
      <span className="w-2 h-2 rounded-full mr-2 bg-current"></span>
      {status}
    </div>
  );
};

const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  const [filter, setFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (seller?._id) {
      dispatch(getAllOrdersOfShop(seller._id));
    }
  }, [dispatch, seller._id]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredOrders = orders
    ?.filter(order => filter === "All" || order.status === filter)
    ?.filter(order => 
      order._id.toLowerCase().includes(searchQuery.toLowerCase()) || 
      order.user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 220,  // Increased the width to accommodate the dropdown
      flex: 1,
      renderCell: (params) => {
        return <StatusIndicator status={params.value} />;
      },
      renderHeader: () => (
        <div className="flex items-center">
          <span>Status</span>
          <FormControl variant="outlined" size="small" className="ml-2">
            <InputLabel htmlFor="status-filter">All</InputLabel>
            <Select
              label="All"
              value={filter}
              onChange={handleFilterChange}
              inputProps={{
                name: "status-filter",
                id: "status-filter",
              }}
              style={{ minWidth: 120 }}  // Increased dropdown width
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Processing">Processing</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="On the way">On the way</MenuItem>
              <MenuItem value="Received">Received</MenuItem>
              <MenuItem value="Refund Success">Refund Success</MenuItem>
              <MenuItem value="Transferred to delivery partner">Transferred to delivery partner</MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = filteredOrders?.map((item) => ({
    id: item._id,
    customerName: item.user?.name || "N/A", // Assuming the customer's name is stored in item.user.name
    itemsQty: item.cart.length,
    total: "TZS " + item.totalPrice.toLocaleString(),
    status: item.status,
  })) || [];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-4 sm:mx-8 pt-1 mt-10 bg-white">
          <div className="w-full mb-4 flex flex-col lg:flex-row items-center justify-between">
            <div className="relative w-full lg:w-2/3 mb-4">
              <AiOutlineSearch className="absolute top-3 left-3 text-gray-500" size={20} />
              <input
                type="text"
                placeholder="Search by Order ID, Customer Name, or Status..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
              className="border-0"
              componentsProps={{
                row: {
                  className: "hover:bg-gray-50",
                },
              }}
              sx={{
                '& .MuiDataGrid-row': {
                  padding: '10px 0',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid #e5e7eb',
                  backgroundColor: '#f9fafb',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: '1px solid #e5e7eb',
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AllOrders;
