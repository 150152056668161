import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { HelmetProvider } from "react-helmet-async";

// Create a root element using ReactDOM.createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={Store}>
    <HelmetProvider>
      <App />
      {/* Be back soon */}
    </HelmetProvider>
  </Provider>
);

reportWebVitals();
