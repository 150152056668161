import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem, FormControl, InputLabel, Select, Typography, Box, Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { server } from "../../server";
import { loadSeller } from "../../redux/actions/user"; // Import the action to load seller info
import { toast } from "react-toastify";

const VerificationForm = () => {
  const dispatch = useDispatch();
  const { user, isSeller } = useSelector((state) => state.user); // Access user and seller info from state

  const [sellerType, setSellerType] = useState("");
  const [formData, setFormData] = useState({
    tinNumber: "",
    businessLicence: null,
    taxClearance: null,
    incorporationCertificate: null,
  });

  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    // Dispatch the action to load seller details only if the seller is not already loaded
    if (!isSeller && user && !user.isSeller) {
      dispatch(loadSeller()); // Ensure seller data is loaded
    }
  }, [dispatch, isSeller, user]); // No need for user.isSeller here

  const handleSellerTypeChange = (event) => {
    setSellerType(event.target.value);
    setFormData({
      tinNumber: "",
      businessLicence: null,
      taxClearance: null,
      incorporationCertificate: null,
    });
    setErrors({});
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tinNumber) {
      newErrors.tinNumber = "TIN Number is required.";
    }

    if (!formData.businessLicence) {
      newErrors.businessLicence = "Business Licence is required.";
    }

    if (!formData.taxClearance) {
      newErrors.taxClearance = "Tax Clearance is required.";
    }

    if (sellerType === "Company") {
      if (!formData.incorporationCertificate) {
        newErrors.incorporationCertificate = "Incorporation Certificate is required.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("sellerType", sellerType);
    formDataToSend.append("tinNumber", formData.tinNumber);
    formDataToSend.append("businessLicence", formData.businessLicence);
    formDataToSend.append("taxClearance", formData.taxClearance);
    if (sellerType === "Company") {
      formDataToSend.append("incorporationCertificate", formData.incorporationCertificate);
    }

    try {
      const response = await axios.post(`${server}/verification/submit-verification`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // Ensure cookies are sent with the request
      });

      setSubmitSuccess(true);
      setFormData({
        tinNumber: "",
        businessLicence: null,
        taxClearance: null,
        incorporationCertificate: null,
      });
      setSellerType("");
      setErrors({});
      toast.success(response.data.message);
    } catch (error) {
      setSubmitError("An error occurred during submission. Please try again.");
      toast.error(error.response?.data?.message || "Submission failed.");
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(false);
    setSubmitError("");
  };

  if (!isSeller) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f9f9f9"
        p={2}
      >
        <Typography variant="h6" color="error">
          You must be logged in as a seller to submit verification documents.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f9f9f9"
      p={2}
    >
      <Box
        bgcolor="white"
        boxShadow={2}
        borderRadius={12}
        p={4}
        maxWidth={500}
        width="100%"
      >
        <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 500 }}>
          Upload Verification Documents
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" error={!!errors.sellerType}>
            <InputLabel htmlFor="seller-type">Select Seller Type</InputLabel>
            <Select
              id="seller-type"
              value={sellerType}
              onChange={handleSellerTypeChange}
              label="Select Seller Type"
              required
            >
              <MenuItem value="Business">Business</MenuItem>
              <MenuItem value="Company">Company</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="TIN Number"
            name="tinNumber"
            value={formData.tinNumber}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            error={!!errors.tinNumber}
            helperText={errors.tinNumber}
          />

          <FormControl fullWidth margin="normal" error={!!errors.businessLicence}>
            <Typography variant="body2" gutterBottom>
              Upload Business Licence
            </Typography>
            <input
              id="businessLicence"
              name="businessLicence"
              type="file"
              onChange={handleFileChange}
              required
            />
            {errors.businessLicence && (
              <Typography variant="caption" color="error">
                {errors.businessLicence}
              </Typography>
            )}
          </FormControl>

          {sellerType === "Company" && (
            <>
              <FormControl fullWidth margin="normal" error={!!errors.incorporationCertificate}>
                <Typography variant="body2" gutterBottom>
                  Upload Incorporation Certificate
                </Typography>
                <input
                  id="incorporationCertificate"
                  name="incorporationCertificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                />
                {errors.incorporationCertificate && (
                  <Typography variant="caption" color="error">
                    {errors.incorporationCertificate}
                  </Typography>
                )}
              </FormControl>
            </>
          )}

          <FormControl fullWidth margin="normal" error={!!errors.taxClearance}>
            <Typography variant="body2" gutterBottom>
              Upload Tax Clearance
            </Typography>
            <input
              id="taxClearance"
              name="taxClearance"
              type="file"
              onChange={handleFileChange}
              required
            />
            {errors.taxClearance && (
              <Typography variant="caption" color="error">
                {errors.taxClearance}
              </Typography>
            )}
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#0018f9",
              color: "#fff",
              marginTop: "24px",
              padding: "12px 0",
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 500,
            }}
          >
            Submit Documents
          </Button>
        </form>
      </Box>

      {/* Success Snackbar */}
      <Snackbar
        open={submitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Documents submitted successfully! Please note that the review process may take up to 4 working days. We appreciate your patience as we verify your documents.
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!submitError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {submitError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VerificationForm;
