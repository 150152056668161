import React from "react";
import styles from "../../styles/styles";
import Azam from "../../Assests/images/Azam.PNG";
import Asas from "../../Assests/images/Asas.PNG";
import interchick from "../../Assests/images/interchick.jpg";
import jambo from "../../Assests/images/jambo.png";
import mati from "../../Assests/images/mati.jpg";
import metl from "../../Assests/images/metl.PNG";

const Sponsored = () => {
  return (
    <div
      className={`${styles.section} hidden sm:block bg-white py-10 px-5 mb-12 cursor-pointer rounded-xl`}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center justify-center">
          <img
            src={Azam}
            alt="Azam"
            className="h-24 w-auto object-contain"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={Asas}
            alt="Asas"
            className="h-24 w-auto object-contain"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={interchick}
            alt="Interchick"
            className="h-24 w-auto object-contain"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={jambo}
            alt="Jambo"
            className="h-24 w-auto object-contain"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={mati}
            alt="Mati"
            className="h-24 w-auto object-contain"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={metl}
            alt="Metl"
            className="h-24 w-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Sponsored;
