// src/components/NotFoundPage.js

import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Home } from "@mui/icons-material";

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f9f9f9"
      p={4}
    >
      <Typography variant="h2" component="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        paragraph
      >
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Home />}
        component={Link}
        to="/"
        size="large"
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
